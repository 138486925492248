import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Contact from '../components/Contact'

import * as S from '../components/About/styled'

const AdvogadosPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        aboutImage: file(relativePath: { eq: "about.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        aboutText: markdownRemark(frontmatter: { key: { eq: "about" } }) {
          frontmatter {
            title
            first
          }
          html
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="Apresentação" />
      <S.TextWrapper style={{ paddingTop: '120px', display: 'block' }}>
        <S.Title>{data.aboutText.frontmatter.title}</S.Title>
        <div className="border" />
        <p>{data.aboutText.frontmatter.first}</p>
        <div
          dangerouslySetInnerHTML={{
            __html: data.aboutText.html
          }}
        />

        <p style={{ textAlign: 'right' }}>
          <Link to="/" style={{ color: '#cfb53b', textDecoration: 'none' }}>
            Voltar
          </Link>
        </p>
      </S.TextWrapper>
      <Contact />
    </Layout>
  )
}

export default AdvogadosPage
